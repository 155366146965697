var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-h-screen bg-white flex"},[_c('div',{staticClass:"flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"},[_c('div',{staticClass:"mx-auto w-full max-w-sm"},[_vm._m(0),_vm._l((_vm.message),function(errMessage){return (_vm.error == true)?_c('div',{staticClass:"mt-10 bg-red-700 text-white rounded-md px-4 py-2"},[_vm._v(" "+_vm._s(errMessage)+" ")]):_vm._e()}),(_vm.error == false && _vm.message)?_c('div',{staticClass:"mt-10 bg-green-700 text-white rounded-md px-4 py-2"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"mt-6"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('div',[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"name"}},[_vm._v(" Name ")]),_c('div',{staticClass:"mt-1 relative rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                      'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': _vm.error,
                    },attrs:{"id":"name","type":"text","required":"","autocomplete":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"},[_c('svg',{staticClass:"h-5 w-5 text-red-500",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})])])])]),_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"email"}},[_vm._v(" Email address ")]),_c('div',{staticClass:"mt-1 relative rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                      'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': _vm.error,
                    },attrs:{"id":"email","type":"email","required":"","autocomplete":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"},[_c('svg',{staticClass:"h-5 w-5 text-red-500",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})])])])]),_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"password"}},[_vm._v(" Password ")]),_c('div',{staticClass:"mt-1 relative rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                      'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': _vm.error,
                    },attrs:{"id":"password","type":"password","required":"","autocomplete":"current-password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"},[_c('svg',{staticClass:"h-5 w-5 text-red-500",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})])])])]),_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"password"}},[_vm._v(" Password Confirmation ")]),_c('div',{staticClass:"mt-1 relative rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                      'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': _vm.error,
                    },attrs:{"id":"password","type":"password","required":"","autocomplete":"current-password"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"},[_c('svg',{staticClass:"h-5 w-5 text-red-500",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})])])])]),_c('div',{staticClass:"mt-6"},[_c('span',{staticClass:"block w-full rounded-md shadow-sm"},[_c('button',{staticClass:"w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none transition duration-150 ease-in-out",attrs:{"type":"submit"}},[(!_vm.performing)?_c('span',[_vm._v("Register")]):_vm._e(),(_vm.performing)?_c('span',[_c('i',{staticClass:"fa fa-circle-notch fa-spin"})]):_vm._e()])])])])])]),_vm._v(" By signing up, you agree to the Terms of Service. You also agree to receive emails from Restream. ")],2)]),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"h-20 w-20 bg-black flex items-center content-center justify-center text-white font-extrabold tracking-wider"},[_c('span',{staticClass:"text-3xl"},[_vm._v(" HC ")])]),_c('h2',{staticClass:"mt-10 text-3xl leading-9 font-extrabold text-gray-900"},[_vm._v(" Register an account ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hidden lg:block relative w-0 flex-1"},[_c('div',{staticClass:"absolute w-full h-full blur z-50"})])}]

export { render, staticRenderFns }