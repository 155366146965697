<template>
  <div class="min-h-screen bg-white flex">
    <div
      class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm">
        <div>
          <div
            class="h-20 w-20 bg-black flex items-center content-center justify-center text-white font-extrabold tracking-wider"
          >
            <span class="text-3xl">
              HC
            </span>
          </div>
          <h2 class="mt-10 text-3xl leading-9 font-extrabold text-gray-900">
            Register an account
          </h2>
        </div>

        <div
          class="mt-10 bg-red-700 text-white rounded-md px-4 py-2"
          v-if="error == true"
          v-for="errMessage in message"
        >
          {{ errMessage }}
        </div>

        <div
          class="mt-10 bg-green-700 text-white rounded-md px-4 py-2"
          v-if="error == false && message"
        >
          {{ message }}
        </div>

        <div class="mt-10">
          <div class="mt-6">
            <form v-on:submit.prevent="login">
              <div>
                <label
                  for="name"
                  class="block text-sm font-medium leading-5 text-gray-700"
                >
                  Name
                </label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="name"
                    type="text"
                    required
                    autocomplete="name"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    :class="{
                      'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': error,
                    }"
                    v-model="name"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                    v-show="error"
                  >
                    <svg
                      class="h-5 w-5 text-red-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="mt-6">
                <label
                  for="email"
                  class="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email address
                </label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="email"
                    type="email"
                    required
                    autocomplete="email"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    :class="{
                      'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': error,
                    }"
                    v-model="email"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                    v-show="error"
                  >
                    <svg
                      class="h-5 w-5 text-red-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="mt-6">
                <label
                  for="password"
                  class="block text-sm font-medium leading-5 text-gray-700"
                >
                  Password
                </label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="password"
                    type="password"
                    required
                    autocomplete="current-password"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    :class="{
                      'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': error,
                    }"
                    v-model="password"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                    v-show="error"
                  >
                    <svg
                      class="h-5 w-5 text-red-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="mt-6">
                <label
                  for="password"
                  class="block text-sm font-medium leading-5 text-gray-700"
                >
                  Password Confirmation
                </label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="password"
                    type="password"
                    required
                    autocomplete="current-password"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    :class="{
                      'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': error,
                    }"
                    v-model="password_confirmation"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                    v-show="error"
                  >
                    <svg
                      class="h-5 w-5 text-red-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="mt-6">
                <span class="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none transition duration-150 ease-in-out"
                  >
                    <span v-if="!performing">Register</span>
                    <span v-if="performing"
                      ><i class="fa fa-circle-notch fa-spin"></i
                    ></span>
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
        By signing up, you agree to the Terms of Service.
You also agree to receive emails from Restream.
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <div class="absolute w-full h-full blur z-50"></div>
      <!-- <img class="absolute inset-0 h-full w-full object-cover z-40 select-none" :src="require('@/assets/img/background.jpeg')" alt=""> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "AuthRegister",
  data() {
    return {
      performing: false,
      email: null,
      name: null,
      password: null,
      password_confirmation: null,
      message: null,

      error: false,
    };
  },
  methods: {
    ...mapActions("auth", ["performLogin"]),
    async login() {
      this.performing = true;
      try {
        let res = await this.$http.post(
          process.env.VUE_APP_API_URL + "/auth/register",
          {
            name: this.name,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
          }
        );
        this.message =
          "Success, your account has been successfully created. You must verify your email before signing in.";
        this.performing = false;
        this.error = false;
      } catch (e) {
        this.message = e.messages;
        this.performing = false;
        this.error = true;
      }
    },
  },
};
</script>
